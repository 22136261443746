    $sidebarFont: 0.8125rem;
    $tableHeaderfont:0.9375rem;
    $tabletdsfont: .9375rem;
    $heading1font: 1.5rem;
    $heading2font: 1.125rem;
    $heading3font: 1rem;
    $inputLablefont: .75rem;
    $inputplaceholderfont: 0.9375rem;
    $greencolor: #0835db;
    $purpleColor: #2aa7ff;
    $borderColor: #d6d6d6;
    $backgroundColor: #f6f7fa;
