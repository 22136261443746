/* You can add global styles to this file, and also import other style files */

@import "./node_modules/angular-notifier/styles.scss";
@import "./assets/styles/custom-styles.scss";
@import "./assets/styles/variables.scss";
// @import "~@swimlane/ngx-datatable/index.css";
// @import "~@swimlane/ngx-datatable/assets/icons.css";

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    width: 100%;
    height: 100%;
    font-size: 0.9rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

.bg-change{
    background-color: #baf4ba !important;
}
.bg-change:focus{
    background-color: #baf4ba;
}

.margin-zero {
    margin: 0px !important;
}

.notifier__container {
    ul {
        margin: 0;
    }
}
.pagination-custom {
    text-align: center;
    button {
        font-size: 14px;
        color: #000;
        cursor: pointer;
        padding: 20px;
        border: 0;
        background-color: transparent;
    }
    button:disabled {
        color: #ddd;
        cursor: default;
    }
}

.link {
    cursor: pointer;
}

.bg-card-body {
    background-color: #fff;
    border: 0.0625rem #e0e0e0;
    border-radius: 0.2rem;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    padding: 30px 30px 5px 30px;
    height: 100%;
    overflow-y: auto;
}
.campaign-form-paddicieng {
    width: 700px;
}
.table th {
    padding: 0.5rem 0.1rem;
}
.table tr {
    width: 100%;
}
.Purchase {
    min-width: 100px !important;
    padding: 0.6875rem 0.9rem !important;
}
.datatable-header-cell-template-wrap div {
    display: inline-block;
}
.headihg th {
    // padding-right: 57px;
}
.ngx-datatable .datatable-body-cell, .ngx-datatable .datatable-header-cell{
    line-height: 1;
    vertical-align: middle;
}
.ngx-datatable .datatable-body .datatable-body-row>div{
    align-items: center;
}

.table td {
    padding: 2px 2px 2px 2px !important;
    line-height: 1;
    font-size: 0.8rem;
    vertical-align: middle;

    p{
        margin-bottom: 0;

    }
}
.campain-table .campain-table-main table{
    padding: 2px 2px 2px 10px !important;
    line-height: 1;

}

.form-outline {
    position: relative;
    margin-bottom: 20px;
    .form-label {
        margin-bottom: 0.5rem;
        color: rgba(0, 0, 0, 0.6);
    }
    .form-control {
        padding: 0.33em 0.75em;
        border: 1px solid #ddd;
        background: transparent;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }
    INPUT:not(:-webkit-autofill),
    SELECT:not(:-webkit-autofill),
    TEXTAREA:not(:-webkit-autofill) {
        animation-name: onautofillcancel;
    }
    .form-control,
    .form-control:focus {
        -webkit-transition: all 0.1s linear;
        transition: all 0.1s linear;
    }
    .form-control:focus {
        border-color: #1976d2 !important;
    }
    .form-control.state-my-input-active ~ .form-label {
        top: -16px !important;
        background-color: #fff;
        z-index: 1;
        color: #1976d2 !important;
    }
    input:not(:placeholder-shown) ~ .form-label {
        border-color: yellow !important;
        top: -16px !important;
        background-color: #fff;
        z-index: 1;
        color: #1976d2 !important;
    }

    input:placeholder-shown {
        // border-color: red !important;
    }
    .form-control:focus ~ .form-label {
        top: -16px;
        background-color: #fff;
        z-index: 1;
        color: #1976d2;
    }
    select.form-control:focus ~ .form-label {
        top: -16px;
        background-color: #fff;
        z-index: 1;
        color: #1976d2;
    }

    select.form-control ~ .form-label {
        position: absolute;
        top: -16px;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        left: 0.75rem;
        padding-top: 0.37rem;
        pointer-events: none;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 0;
        background-color: #fff;
    }
    .form-control ~ .form-label {
        position: absolute;
        top: 1px;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        left: 0.75rem;
        padding-top: 0.37rem;
        pointer-events: none;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 0;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6;
        color: #4f4f4f;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #bdbdbd;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }
}

.form-outline:focus ~ .form-label {
    top: -16px !important;
    background-color: #fff;
    z-index: 1;
    color: #1976d2 !important;
}

.m-l-15 {
    margin-left: 15px;
}

.w-60p {
    width: 60%;
}

.w-40p {
    width: 40%;
}

.m-h-70vh {
    min-height: 70vh;
}
.downloadBtn {
    height: 41px;
}

.chekbox-notify {
    input[type=checkbox] {
        width: 17px;
        height: 17px;
        top: -2px;
    }
}

.reset-btn {
    background-color: #000;
    border: 0;
    padding: 0.6875rem 1.125rem;
    font-size: 0.9375rem;
    line-height: 1rem;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    text-align: center;
    min-width: 115px;
    border-radius: 0.25rem;
}

.icon-images {
    img {
        width: 30px;
    }

    img:hover {
        transform: scale(1.2);
    }
}
.table-input{
    padding: 5px;
    height: 28px;
    font-size: 13px;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 3px;
    // width: 100px !important;
    border-right: 1px solid #ddd;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
    padding: 3px;
    // width: 100px !important;
}
.selectDropdown .ngx-dropdown-container .ngx-dropdown-button {
    min-height: 38px !important;
}

.link .fa,
.link .fas {
    font-size: 18px;
}

.desp_help_icon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #ede594;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
.tooltip .tooltip-inner {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 0px 20px 0px #00000052;
    max-width: 500px;
    text-align: left;
}
.tooltip .tooltip-arrow::before {
    border-right-color: #fff;
}

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: $backgroundColor;
}

.login-main {
    width: 600px;
    box-shadow: 1px 1px 17px 9px #dce0e9;
}

.accounts-main {
    background-color: white;
    padding: 20px 0 20px 20px;
    height: 80px;
    width: 100%;
    .accounts-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .logo {
            max-width: 150px;
            padding: 15px;
            img {
                width: 100%;
            }
        }
        .navigation {
            a {
                color: $purpleColor;
                padding: 20px;
                // margin: 10px;
                position: relative;
                font-size: 0.9375rem;
                text-decoration: none;
                font-weight: 600;
                display: inline-block;
                width: 110px;
                text-align: center;
            }
            a.active::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 7px;
                background-color: $greencolor;
                bottom: 0;
                left: 0;
                bottom: -11px;
            }
        }
    }
}

.login-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $backgroundColor;
    padding: 0 100px;
    h1 {
        font-weight: 500;
        color: #0e0e0e;
        font-size: $heading1font;
        margin-top: 30px;
        margin-bottom: 40px;
        span {
            color: $greencolor;
        }
    }
    .login-form {
        width: 100%;
        margin-bottom: 50px;
        input {
            background-color: $backgroundColor;
        }
        .inputText {
            // padding-left: 50px;
            // background-image: url(/assets/images/user-icon.png);
            // background-position: left;
            // background-repeat: no-repeat;
            // background-size: contain;
        }
        .forgotpass {
            .forgot {
                text-align: right;
            }
            a {
                color: #0e0e0e;
                font-weight: 500;
                cursor: pointer;
            }
        }
        button {
            margin: auto;
            display: table;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}

.user-input-wrp {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 20px;
    label,
    input,
    select {
        transition: all 0.2s;
        touch-action: manipulation;
    }
    input:focus {
        outline: 0;
        border-bottom: 1px solid $borderColor;
    }
    label {
        letter-spacing: 0.05em;
        color: #bcbcbc;
    }
    input {
        font-size: $inputplaceholderfont;
        border: 0;
        border-bottom: 1px solid $borderColor;
        font-family: inherit;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
        padding-left: 10px;
    }
    select {
        font-size: 1.5rem;
        border: 0;
        border-bottom: 1px solid $borderColor;
        font-family: inherit;
        // -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
    }
    input:placeholder-shown + label,
    select:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125rem) scale(1.5);
    }
    /**
      * By default, the placeholder should be transparent. Also, it should
      * inherit the transition.
      */
    ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
    }
    /**
      * Show the placeholder when the input is focused.
      */
    input:focus::-webkit-input-placeholder,
    select:focus::-webkit-input-placeholder {
        opacity: 1;
    }
    /**
      * When the element is focused, remove the label transform.
      * Also, do this when the placeholder is _not_ shown, i.e. when
      * there's something in the input at all.
      */
    input:not(:placeholder-shown) + label,
    input:focus + label {
        transform: translate(0, 0) scale(1);
        cursor: pointer;
    }
    select:not(:placeholder-shown) + label,
    select:focus + label {
        transform: translate(0, 0) scale(1);
        cursor: pointer;
    }
}

.field-main {
    position: relative;
    .text-danger {
        position: absolute;
        bottom: -20px;
    }
}
.search-section .input-group {
    max-width: 500px;
    margin: auto;
}

.user-input-wrpselect {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    .inputText {
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid $borderColor;
        box-shadow: none !important;
        color: #000;
        font-size: $inputplaceholderfont;
        padding-left: 10px;
    }
    .inputText:focus {
        border-color: $borderColor;
        border-width: medium medium 2px;
    }
    .floating-label {
        position: absolute;
        pointer-events: none;
        top: 18px;
        left: 10px;
        transition: 0.2s ease all;
        font-size: 1.5rem;
        color: #bcbcbc;
    }
    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label {
        top: 0px;
        left: 10px;
        font-size: $inputplaceholderfont;
        opacity: 1;
    }
    select:focus ~ .floating-label,
    select:not(:focus):valid ~ .floating-label {
        top: 0px;
        left: 10px;
        font-size: 0.9375rem;
        opacity: 1;
    }
}

.password-reset-main {
    .login-inner {
        position: relative;
    }
    .login-btn {
        background-color: $purpleColor;
        border: 0;
        padding: 15px 37px;
        font-size: 1.625rem;
        color: #fff;
        font-weight: 500;
        position: absolute;
        left: 40px;
        bottom: 40px;
    }
}

.bottomLineInput {
    input {
        border: none;
        border-bottom: 1px solid $borderColor;
        border-radius: 0px;
    }
    select {
        border: none;
        border-bottom: 1px solid $borderColor;
        border-radius: 0px;
    }
}

.form-group {
    position: relative;
    label {
        font-size: $inputLablefont;
        margin-bottom: 0;
    }
    input {
        font-size: 0.9rem;
    }
    textarea {
        font-size: 0.9rem;
    }
    select {
        font-size: 0.9rem;
    }
    .error {
        // position: absolute;
        font-size: 0.9rem;
    }
}

.error {
   color: red;
    font-size: 12px;
}
.scroll-table{
    display: block;
    overflow-x: scroll;
}

.register-page {
    padding: 30px;
    .login-main {
        width: 100%;
        background-color: $backgroundColor;
    }
    .mat-horizontal-stepper-header-container {
        display: none;
    }
    .register-inner {
        padding: 15px;
        // background-color: #ffffff;
        input {
            background-color: $backgroundColor;
        }
        select {
            background-color: $backgroundColor;
        }
    }
    .register-padding {
        padding: 30px 30px 30px 30px;
    }
    .btn-displly-table {
        display: table !important;
        margin: auto !important;
        padding-top: 30px;
    }
    h3 {
        text-align: center;
        line-height: 1;
    }
    h5 {
        text-align: center;
        line-height: 1;
        margin-bottom: 50px;
    }
    .register-btns {
        display: flex;
        justify-content: space-between;
    }
    .iagree {
        margin-top: 20px;
        text-align: center;
        label {
            color: #bcbcbc;
            font-weight: 500;
            a {
                font-weight: 700;
                cursor: pointer;
            }
            input {
                margin-right: 15px;
                width: 20px;
                height: 20px;
            }
        }
    }
}

.primary-btn {
    background-color: $greencolor;
    border: 0;
    padding: 0.6875rem 1.125rem;
    font-size: 0.9375rem;
    line-height: 1rem;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    text-align: center;
    min-width: 130px;
    border-radius: 0.25rem;
}

.primary-btn:disabled {
    background-color: #bfbfbf !important;
    cursor: not-allowed;
}

.btn-xl {
    width: 300px;
}

.btn-lg {
    width: 150px;
}

.arrow-left i {
    padding-right: 5px;
}

.arrow-right i {
    padding-left: 5px;
}
// li.nav-item a span.badge{
//     display: none !important;
// }
// li.nav-item.active a span.badge{
//     display: inline-block !important;
// }
.btn-icon{
    width: 20px;
}
.modal-xl{
    min-width: 1400px;
}
.modal-xl-max{
    min-width: 1100px;
}


.phone-verification {
    display: flex;
    justify-content: center;
    padding: 40px;
    .sendCode {
        text-align: center;
        label {
            color: #bcbcbc;
            font-size: $inputLablefont;
        }
    }
}

.modal-main {
    .modal-head {
        background-color: #f0f0f0;
        padding: 17px;
        h3 {
            color: #0e0e0e;
            font-weight: 700;
            text-align: center;
            font-size: $heading3font;
        }
    }
}

.phone-numberselection {
    display: flex;
    border-bottom: 1px solid $borderColor;
    padding-right: 10px;
    align-items: baseline;
    .swelect {
        padding-right: 10px;
    }
    select {
        height: 24px;
        // background-color: $purpleColor !important;
        background-color: transparent;
        border: 0;
        color: #000;
        font-size: 0.9375rem;
    }
    input {
        border-bottom: 0 !important;
    }
    .user-input-wrp {
        margin-bottom: 0;
    }
}

.mobileno-update {
    max-width: 400px;
    margin: auto;
    text-align: left;
    flex-direction: column;
}

.headingh2 {
    color: #0e0e0e;
    font-weight: 500;
    font-size: 2rem;
    color: $greencolor;
}

.headingh3 {
    color: #0e0e0e;
    font-weight: 500;
    font-size: 2rem;
}

.upload-docs-inner {
    .fileInputBtn {
        display: table;
        align-self: flex-end;

        input {
            width: 0;
            height: 0;
        }
    }
    .uploadntn {
        display: flex;
        flex-direction: column;
        .upload-name {
            background-color: $backgroundColor;
            padding: 10px;
            font-size: 0.975rem;
            color: #808080;
            font-weight: 300;
            border: 1px solid $greencolor;
        }
    }
}

/////////////////// DASHBOARD DESIGN ///////////////////////////@at-root
.wrapper-main {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100%;

    .sidebar-wrapper {
        width: 60px;
        background-color: #ffffff;
        height: 100vh;
        transition: all 0.3s ease;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
    }
    .sidebar-main {
        height: 100%;
        border: 1px solid $borderColor;
        .logo {
            text-align: center;
            img {
                width: 100%;
                // margin-top: 10px;
                padding: 14px 10px;
            }
        }
        .dropdownItem {
            flex-direction: column;
            .subItem {
                padding-bottom: 5px;
                padding-right: 20px;
                padding-left: 30px;
                padding-top: 5px;
                .image {
                    width: 20px;
                }
            }
        }
        .menu-items li:focus {
            outline: none;
        }
        .menu-items {
            li {
                position: relative;
                padding: 20px 20px;
                font-size: $sidebarFont;
                color: $greencolor;
                cursor: pointer;
                display: flex;
                a {
                    padding-left: 10px;
                    display: none;
                }
                .image {
                    width: 20px;
                    text-align: center;
                    img {
                        max-width: 100%;
                        max-height: 20px;
                    }
                }
            }
            li.active {
                background-color: $backgroundColor;
                color: $greencolor;
            }
            li:hover {
                background-color: $backgroundColor;
                color: $greencolor;
            }
            .chiled-li:focus {
                outline: none;
            }
            li:hover::before {
                position: absolute;
                content: "";
                left: 0;
                height: 100%;
                width: 5px;
                background-color: $purpleColor;
                top: 0;
            }
            li.active::before {
                position: absolute;
                content: "";
                left: 0;
                height: 100%;
                width: 5px;
                background-color: $purpleColor;
                top: 0;
            }
        }
    }
    .sidebar-main {
        li.dropdownItem {
            padding: 0 !important;
        }
        li.dropdownItem:hover {
            padding: 0;
        }
        li.dropdownItem.active {
            padding: 0;
        }
        li.dropdownItem .head-menu {
            padding: 20px;
        }
        // .wrapper-main .sidebar-wrapper:hover li.dropdownItem .head-menu {
        //     padding: 20px 30px !important;
        // }
        .dropdownItem .subItem:hover {
            background-color: $backgroundColor;
        }
        .dropdownItem .subItem.active {
            background-color: $backgroundColor;
        }
    }
    .sidebar-main.closeDropDowns .collapse {
        display: none;
    }
    .content-wrapper {
        width: calc(100% - 60px);
        min-height: 100%;
        margin-left: 60px;
        transition: all 0.3s ease;
        .content {
            width: 100%;
            min-height: calc(100vh - 81px);
            background-color: $backgroundColor;
            padding: 10px 20px 20px 20px;

            .content-inner-wrapper {
                background-color: #fff;
                border-radius: 10px;
                border: 1px solid #ddd;
                padding: 10px;
            }
        }
    }
    .top-bar-wrapper {
        width: 100%;
    }
    .top-bar-main {
        padding: 5px 20px;
        width: 100%;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $borderColor;
        .user-drop-down {
            padding-right: 20px;
            button.dropdown-toggle {
                padding: 0;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background-color: transparent;
                border-color: transparent;
                div {
                    overflow: hidden;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
            .dropdown-toggle::after {
                display: inline-block;
                margin-left: 14px;
                vertical-align: 0.255em;
                content: "";
                border-top: 5px solid $borderColor;
                border-right: 5px solid transparent;
                border-bottom: 0;
                border-left: 5px solid transparent;
                position: absolute;
                right: -16px;
                top: 50%;
            }
        }
        .right-part {
            display: flex;
            align-items: center;
            p {
                margin: 0;
                margin-right: 20px;
                font-size: 0.9rem;
                color: #0e0e0e;
                font-weight: 400;
                span {
                    font-weight: 700;
                    color: $greencolor;
                }
            }
        }
    }
}

.sidebarMinimize {
    .sidebar-wrapper {
        width: 250px;
        transition: all 0.3s ease;
        .logo {
            img {
                width: 60%;
            }
        }
    }
    .sidebar-main {
        .dropdownItem {
            .subItem {
                padding-bottom: 15px;
                padding-bottom: 20px;
                padding-left: 50px;
                padding-top: 15px;
            }
        }
        .menu-items {
            li {
                padding: 20px 30px;
                a {
                    display: block;
                    line-height: 1.8;
                }
            }
            li.dropdownItem .head-menu {
                padding: 20px 30px;
            }
        }
    }
    .content-wrapper {
        margin-left: 250px;
        transition: all 0.3s ease;
    }
    .menu-items {
        li {
            padding: 20px 30px;
            a {
                display: block;
            }
        }
    }
}

.sidebar-wrapper:hover {
    width: 250px;
    transition: all 0.3s ease;
    .logo {
        img {
            width: 60%;
        }
    }
    .menu-items {
        li {
            // padding: 20px;
            a {
                display: block;
            }
        }
        .subItem {
            padding-bottom: 15px;
            padding-bottom: 20px;
            padding-left: 50px;
            padding-top: 15px;
        }
    }
}

.conponent-heading {
    font-weight: 400;
    font-size: $heading1font;
    color: $greencolor;
    span {
        font-weight: 700;
    }
}

.welcome-main {
    button {
        margin: auto;
        display: table;
    }
    .welcome-image {
        img {
            width: 100%;
        }
    }
}

// .carousel {
//     text-align: -webkit-center;
//     text-align: center;
// }
.carousel-img {
    display: block;
    width: 100%;
}
.quick-guide-main {
    .quick-guide-slider {
        // padding-top: 40px;
        text-align: -webkit-center;
        p {
            color: $greencolor;
            font-size: 1.875rem;
            text-align: center;
            padding-bottom: 10px;
        }
        .guide-slider {
            background-color: $backgroundColor;
            padding: 0 50px 9px 50px;
            width: 80%;
            .carousel:focus {
                outline: none;
            }
            .carousel-item {
                margin-right: 0;
                .item {
                    padding: 20px;
                    text-align: -webkit-center !important;
                    text-align: center;
                }
            }
            .carousel-control-prev-icon {
                position: absolute;
                left: -30px;
                height: 35px;
                background-image: url(./assets/images/next.png);
            }
            .carousel-control-next-icon {
                position: absolute;
                right: -30px;
                height: 35px;
                background-image: url(./assets/images/prev.png);
            }
            .carousel-control-next,
            .carousel-control-prev {
                opacity: 3;
            }
            .carousel-indicators {
                bottom: -50px;
            }
        }
    }
    button {
        margin: auto;
        display: table;
    }
}

.dashboard-tables .table-new {
    height: 400px;
    overflow-y: auto;
}
.createcampaignBtns {
    // display: flex;
    flex-wrap: wrap;
    button {
        margin-bottom: 5px;
    }
}

.accounts-bg {
    // background-image: url("/assets/images/login bkg_with logo.jpg");
    background-size: cover;
    min-height: 100vh;
}

.modal-footer {
    justify-content: center;
}

///////////////////////////////////////////ResPonsive Media Quries//////////////////////////////
@media only screen and (max-width: 1440px) {
    .product-section .product-main .prducts-flex {
        margin: 1%;
        padding: 20px;
    }
    .primary-btn {
        min-width: 115px;
    }
}

@media only screen and (max-width: 1200px) {
    .product-section .product-main .prducts-flex {
        flex-basis: 30.3%;
    }
    .capmaign-file-list-main {
        flex-direction: column;
    }
    .capmaign-file-list-main .file-error-main {
        margin-left: 0px;
        margin-top: 10px;
    }
    .capmaign-file-list-main .file-error-main .file-error-text P {
        font-size: 0.925rem;
    }
    .product-section .product-main .prducts-flex {
        padding: 30px;
    }
    .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text {
        padding: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .product-section .product-main .prducts-flex {
        flex-basis: 48%;
    }
    .campaign-section h1 {
        font-size: 0.9rem;
    }
    .campaign-section .heading-flex p span {
        font-size: 0.8rem;
    }
    .campain-form-main .cost-form .cost-text p span {
        font-size: 0.8rem;
    }
    .primary-btn {
        min-width: 100px;
        font-size: 0.7375rem;
        margin: 0;
    }
    .opload-file-section {
        padding: 0px;
    }
    .campain-form-main .cost-form .input-style label {
        font-size: $inputLablefont;
    }
    .campain-form-main .cost-form .input-style .form-control {
        font-size: $inputplaceholderfont;
    }
    .Customer-table p {
        font-size: $tabletdsfont;
    }
    .upload-table th {
        font-size: $tableHeaderfont;
    }
    .upload-table td {
        font-size: $tabletdsfont;
    }
    .campain-form-main .cost-form .cost-form-butn-main {
        max-width: 250px;
    }
    .product-section .product-main .prducts-flex {
        padding: 30px;
    }
    .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text {
        padding: 25px;
    }
    .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text p {
        font-size: 0.6rem;
    }
    .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text h1 {
        font-size: 0.7rem;
        margin-bottom: 0px;
    }
    .product-section .product-main .prducts-flex .product-Marketing-text h1 span {
        font-size: 0.9rem;
    }
    .product-section .product-main .prducts-flex .product-Marketing-text h1 {
        font-size: 0.6rem;
    }
    .product-section .product-main .prducts-flex .product-Marketing-text h3 {
        font-size: 0.75rem;
    }
    .faqs-section .faqs-main .button-text p {
        font-size: 0.7375rem;
    }
}

@media only screen and (max-width: 768px) {
    .capmaign-file-list-main {
        flex-direction: unset;
        align-items: center;
        justify-content: center;
    }
    .capmaign-file-list-main .file-error-main {
        margin-left: 10px;
        margin-top: 0px;
    }
    .opload-file-section {
        margin-top: 30px;
    }
    .campain-form-main .cost-form .cost-form-butn-main {
        max-width: 100%;
    }
    // .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text {
    //     padding: 14px;
    // }
    // .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text h1 {
    //     font-size: 0.6rem;
    // }
    .primary-btn {
        padding: 0.5875rem 1rem;
    }
    .headingh2 {
        font-size: 1.5rem;
    }
    .headingh3 {
        font-size: 1.5rem;
    }
    .upload-docs-main .complete-ref {
        margin-left: 30px;
        margin-top: 30px;
    }
    .form-flex .middel-bacis {
        padding-left: 15px;
    }
    .form-flex .last-bacis {
        padding-left: 15px;
    }
    .faqs-section .faqs-main .button-text p {
        max-width: 400px;
    }
    .faqs-section .faqs-main .content-custom p {
        font-size: 0.8rem;
    }
    .campaign-sec .compain-img {
        margin: auto;
        padding-bottom: 30px;
    }
    .campaign-sec .compain-graph {
        margin: auto;
    }
    .register-page .register-padding {
        padding: 30px 15px 15px 15px;
    }
}

@media only screen and (max-width: 610px) {
    .capmaign-file-list-main {
        flex-direction: column;
        align-items: center;
    }
    .campain-form-main .cost-form .cost-form-butn-main {
        max-width: 244px;
        margin: auto;
    }
    .capmaign-file-list-main .file-error-main {
        margin-top: 10px;
    }
    .campaign-section .heading-flex {
        display: flex;
        align-items: center;
        margin-bottom: 31px;
        flex-direction: column;
    }
    .campaign-section .heading-flex p {
        text-align: center;
    }
    .campain-form-main .cost-form .cost-text p {
        text-align: center;
    }
    .capmaign-file-list-main .file-btn-text .file-btn-haeading {
        margin-right: 5px;
    }
    .capmaign-file-list-main .file-error-main .file-error-text P {
        font-size: 0.79rem;
    }
    ///////////LOG-IN PAGE/////////////
    .login-page {
        padding: 0px 30px 0px 30px;
    }
    .login-main {
        width: 100%;
    }
    .btn-xl {
        width: 200px;
    }
    .login-inner .login-form .forgotpass a {
        font-size: 0.9rem;
    }
    .login-inner {
        padding: 0 49px;
    }
    .accounts-main .accounts-header .navigation a {
        padding: 19px;
        margin: 0px;
        font-size: 0.9rem;
    }
    .login-inner h1 {
        font-size: 1.3rem;
    }
    .accounts-main .accounts-header .logo {
        max-width: 132px;
    }
    .product-section .product-main .prducts-flex {
        flex-basis: 100%;
        box-shadow: 0px 0px 12px 0 rgb(0 0 0 / 48%);
    }
    .Trasection-main-border {
        display: table;
        margin: auto;
    }
    .form-flex {
        /* display: flex; */
        flex-wrap: wrap;
    }
    .form-flex .form-right {
        flex-basis: 100%;
        padding-left: 0px;
    }
    .form-flex .form-left {
        flex-basis: 100%;
    }
    .headingh2 {
        font-size: 1.5rem;
        text-align: center;
    }
    .headingh3 {
        font-size: 1rem;
    }
    .form-flex .first-bacis {
        flex-basis: 100%;
    }
    .form-flex .middel-bacis {
        flex-basis: 100%;
        padding-left: 0px;
    }
    .form-flex .last-bacis {
        flex-basis: 100%;
        padding-left: 0px;
    }
    .accounts-main .accounts-header .navigation a.active::before {
        width: 81%;
        left: 12px;
    }
    .upload-docs-main .complete-ref {
        align-self: center;
    }
    .table-main .campain-table .table-boder tr td p {
        color: #000;
        font-size: 0.63rem;
        margin: 0;
    }
    .table-main .campain-table .table-boder tr td span {
        font-size: 0.75rem;
    }
    .welcome-main {
        text-align: center;
    }
    .product-section .product-main .prducts-flex .product-button {
        margin: 21px 32px 0px 0px;
    }
    .password-reset-main .login-btn {
        padding: 12px 26px;
        font-size: 1rem;
        left: unset;
    }
    .faqs-section .faqs-main .button-text {
        flex-direction: column;
    }
    .faqs-section .faqs-main .button-text p {
        max-width: unset;
        text-align: center;
        margin-bottom: 30px;
    }
    .faqs-section .faqs-main .panel .panel-title h1 {
        font-size: 0.8rem;
    }
    .faqs-section .faqs-main .panel .panel-body {
        padding: 20px;
    }
    .faqs-section .faqs-main .panel .panel-body {
        padding: 20px;
    }
    .transection-inputs .recharge-button {
        display: table;
        margin: auto;
        padding-top: 30px;
    }
}

@media only screen and (max-width: 410px) {
    .capmaign-file-list-main .file-btn-text {
        text-align: center;
    }
    .upload-table th {
        font-size: $tableHeaderfont;
    }
    .upload-table td {
        font-size: $tabletdsfont;
    }
    .login-inner {
        padding: 0 23px;
    }
    .form-group label {
        font-size: $inputLablefont;
    }
    .accounts-main .accounts-header .logo {
        max-width: 120px;
    }
    .accounts-main .accounts-header .navigation a {
        padding: 8px;
        font-size: 0.8rem;
    }
    .accounts-main {
        padding: 14px;
        height: 67px;
    }
    .transection-inputs {
        text-align: center;
    }
    .accounts-main .accounts-header .navigation a.active::before {
        left: 7px;
        bottom: -16px;
    }
    .primary-btn {
        min-width: 90px;
    }
    .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text {
        padding: 15px;
    }
    .product-section .product-main .prducts-flex {
        padding: 14px;
    }
    .product-section .product-main .prducts-flex {
        margin-top: 11px;
    }
    .product-section .product-main .prducts-flex .product-button {
        margin: 21px 0px 0px 0px;
    }
    .faqs-section .faqs-main .content-custom p {
        /* font-size: 0.8rem; */
        text-align: center;
    }
}

.checkbox-switch {
    label {
        font-size: 13px;
        vertical-align: middle;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 47px;
        height: 28px;
        margin-left: 2px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.invalid-records-list {
    input {
        width: 100%;
    }
}

.content-inner-wrapper, .sidebar-wrapper {
    box-shadow: 0 0 15px #ccc !important;
}

.modal-content {
    box-shadow: 0 2px 5px #606060;
}

.btn-sm-custom{
  padding: 3px 16px;
    line-height: 1;
    font-size: 14px;
    height: 31px;
    min-width: 99px;
}




