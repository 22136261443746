    ///////////////UNIversal calasses////////
        @import './variables.scss';

    .bold {
        font-weight: 700;
    }
    
    .btn-color {
        background-color: $greencolor !important;
    }
    
    .menu-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    
    .menu-btn .manu-btn-txt p {
        margin-left: 10px;
        color: $greencolor;
        margin-bottom: 0;
        font-size: 1.3rem;
    }
    
    .form-flex {
        display: flex;
    }
    
    .form-flex .form-left {
        display: flex;
        flex-wrap: wrap;
    }
    
    .form-flex .form-left .form-group {
        flex-basis: 48%;
        margin-right: 2%;
    }
    
    .form-flex .form-right {
        padding-left: 50px;
    }
    
    .form-flex .form-control {
        border: none;
        border-bottom: 1px solid $borderColor;
        border-radius: 0px;
    }

    
    .form-flex .form-control:focus {
        // outline: none;
    }
    .form-flex .form-control:focus{
        outline: 1px solid #80bdff !important;
    }
    
    .form-flex .first-bacis {
        display: flex;
        flex-wrap: wrap;
    }
    
    .form-flex .first-bacis .form-group {
        flex-basis: 30.3%;
        margin-right: 1%;
        ;
    }
    
    .form-flex .middel-bacis {
        flex-basis: 33.3%;
        padding-left: 30px;
    }
    
    .form-flex .last-bacis {
        flex-basis: 33.3%;
        padding-left: 30px;
    }
    
    .form-flex .form-control:focus {
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
    }
    
    /////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////compain page section ///////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////

    
    .campaign-sec {
        // margin-top: 100px;
    }
    
    .campaign-sec .compain-img {
        max-width: 300px;
        box-shadow: 0 2px 1px -2px rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }
    
    .campaign-sec .compain-img img {
        width: 100%;
        max-height: 205px;
    }
    
    .campaign-sec .compain-graph {
        max-width: 500px;
        box-shadow: 0 2px 1px -2px rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }
    
    .campaign-sec .compain-graph img {
        width: 100%;
        max-height: 205px;
    }
    
    .campaign-sec .campaign-heading {
        text-align: right;
        display: flex;
    }
    
    .campaign-heading {}
    
    .campaign-sec p {
        right: 0;
        font-size: 0.9375rem;
        color: $greencolor;
        margin-bottom: 3px;
    }
    
    .campaign-sec p span {
        font-weight: 700;
    }
    .campaign-sec ul{


        li{
            font-size: 0.9375rem;
            color: $greencolor;
            margin-bottom: 3px;  
        }
    } 
    
    .table-main {
        margin-top: 10px;
    }
    
    .table-main .campain-table h1 {
        font-size: $tabletdsfont;
        font-weight: 700;
        margin-bottom: 10px;
    }
    
    .table-main .campain-table .table-boder .option-image {
        max-width: 5px;
        padding-top: 10px;
    }
    
    .table-main .campain-table .table-boder .option-image img {
        width: 4px;
    }
    
    .table-main .campain-table .table-boder {
        width: 100%;
        border: 1px solid $borderColor;
        border-top: none;
        border-left: none;
        border-right: none;
        background-color: #ffffff;
    }
    
    .table-main .campain-table td {}
    
    .table-main table {
        table-layout: fixed;
        tbody {
            display: block;
            overflow-x: scroll;
            background-color: #ffffff;
        }
    }
    .manage-campaignTable table tbody{
        display: table-row-group;
    }
    
    .table-main table {
        th {
            border-top: none;
            border-left: none;
            background-color: #ffffff;
            // width: 20%;
            color: #000;
            min-width: 150px;
        }
    }
    
    // .table-main table tr{
    //     position: relative;
    // }
    .table-main table tr td:last-child {
        // width: 55px;
        // text-align: center;
    }
    
    .table-main table tr td .user-drop-down .dropdown-img {
        padding: 0 10px;
    }
    
    .table-main tr td .user-drop-down .dropdown-img img {
        width: 4px;
    }
    
    .table-main .campain-table .table-boder .user-drop-down .option-font-size {
        font-size: $tabletdsfont;
    }
    
    .table-main .campain-table .table-boder tr td .table-circle {
        border-radius: 50%;
        background-color: $greencolor;
        color: #ffffff;
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: $tabletdsfont;
        font-weight: 500;
        padding: 5px;
    }
    
    .table-main .campain-table .table-boder tr td p {
        color: #000;
        font-size: 0.90rem;
        margin: 0;
    }
    
    .table-main .campain-table .table-boder tr td span {
        font-size: 0.7rem;
    }
    
    .table-main .campain-table .table-boder tr td span i {
        font-size: $tabletdsfont;
        // padding-left: 5px;
    }
    
    .table-main .campain-table .table-boder tr td p span {
        color: $greencolor;
    }
    
    .drop-flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .drop-flex .img-drop-text .img-drop img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    
    .drop-flex .img-drop-text .img-drop {
        margin: auto;
    }
    
    .drop-flex .img-drop-text {
        text-align: center;
        width: 120px;
    }
    
    .drop-flex .img-drop-text p {
        margin-right: 0 !important;
    }
    
    .drop-flex .manu-drop a {
        color: $greencolor;
        text-decoration: none;
    }

    .manu-drop-head {
        font-weight: 700;
        color: white;
        font-size: 1.2rem;
        text-align: center;
        background-color: $greencolor;
    }
    
    .table-main .dropdown-item:focus,
    .dropdown-item:hover {
        color: #ffffff !important;
        text-decoration: none;
        background-color: $greencolor;
    }
    
    /////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////compain page section---2 ///////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////
    .campaign-section .heading-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .campaign-section .test-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    .campaign-section .test-flex .status h3 {
        color: $greencolor;
        font-size: $heading3font;
        margin-bottom: 0px;
    }
    
    .campaign-section .heading-flex button {
        background-color: $greencolor;
        color: #ffffff;
        border: none;
        font-size: 0.9375rem;
        padding: 10px;
        margin-left: 10px;
    }
    
    .campaign-section .heading-flex p {
        font-size: 0.9375rem;
        color: $greencolor;
        margin-right: 10px;
        font-weight: 600;
    }
    
    .campaign-section .heading-flex h1 {
        font-size: $heading1font;
    }
    
    .campaign-section .heading-flex p span {
        font-weight: 700;
        color: $purpleColor;
    }
    
    .table-search-dropdown .dropdown-toggle::after {
        display: none;
    }
    
    .table-search-dropdown a {
        text-decoration: none;
        color: $greencolor;
    }
    
    .table-search-dropdown a h1 img {
        display: inline-block;
        margin-right: 5px;
        width: 20px;
    }
    
    .table-search-dropdown .dropdown-menu {
        padding: 0px;
        border: 0px;
        width: 200px;
        font-size: 0.9375rem;
    }
    
    .table-search-dropdown .dropdown-menu .form-control:focus {
        box-shadow: none;
        border-color: $borderColor;
    }
    
    // .history-drop a:hover {
    //     background-color: transparent;
    // } 
    .campain-form-main .cost-form .cost-text .input-style .cost-form-butn-main .cost-form-butn-flex .capmain-btn {
        flex-basis: 50%;
    }
    
    .compain-form-padding {
        padding: 0px 30px 0px 30px;
    }
    
    .campain-form-main .cost-form .cost-text p {
        color: $greencolor;
        margin-bottom: 2px;
        font-size: 0.9rem;
    }
    
    .campain-form-main .cost-form .cost-text p span {
        font-weight: 700;
    }
    
    .campain-form-main .cost-form form {
        margin-top: 20px;
    }
    
    .campain-form-main .cost-form .input-style label {
        color: #495057;
    }
    
    .campain-form-main .cost-form .input-style p {
        margin-bottom: 0;
        font-size: 0.6rem;
        opacity: 0.5;
        margin-top: 10px;
    }
    
    .campain-form-main .cost-form .input-style .calender-background {
        background-image: url(/assets/images/calender.png);
        background-position: 96%;
        background-repeat: no-repeat;
        background-size: 22px;
    }
    
    .campain-form-main .cost-form .input-style .form-control {
        border: none;
        border-bottom: 1px solid $borderColor;
        background-color: transparent;
        border-radius: 0px;
    }
    
    .campain-form-main .cost-form .input-style .form-control:focus {
        box-shadow: none;
    }
    
    .campain-form-main .cost-form .input-style .message-text {
        border: none;
        border-bottom: 1px solid $borderColor;
        border-right: 1px solid $borderColor;
        background-color: transparent;
        border-radius: 0px;
        height: 140px;
    }
    
    .campain-form-main .cost-form .cost-form-butn-main {
        display: flex;
        flex-wrap: wrap;
        max-width: 340px;
    }
    
    .campain-form-main .cost-form .cost-form-butn-main .capmain-btn {
        margin-bottom: 10px;
        margin-left: 2%;
    }
    
    .opload-file-section {
        padding: 0px 30px 0px 30px;
    }
    
    .campaign-section h1 {
        color: $greencolor;
        font-size: $heading1font;
        margin-bottom: 0;
    }
    
    .capmaign-file-list-main {
        display: flex;
    }
    
    .capmaign-file-list-main .file-btn-text input {
        width: 0;
        height: 0;
    }
    
    .capmaign-file-list-main .file-btn-text .file-btn-haeading {
        margin-right: 20px;
        font-size: 0.9rem;
    }
    
    .capmaign-file-list-main .file-error-main .file-error-img {
        max-width: 30px;
    }
    
    .capmaign-file-list-main .file-error-main .file-error-img img {
        width: 100%;
    }
    
    .capmaign-file-list-main .file-error-main {
        display: flex;
        margin-left: 20px;
        align-items: center;
    }
    
    .capmaign-file-list-main .file-error-main .file-error-text {
        margin-left: 10px;
    }
    
    .capmaign-file-list-main .file-error-main .file-error-text P {
        margin-bottom: 0px;
        font-size: .625rem;
    }
    
    //////////////////////////////////////POP-UP////////////////////
    .popup-section {
        background: #ffffff;
        width: 500px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 6px;
    }
    
    .popup-section .popup-top-bar {
        border-bottom: 1px solid #b6b0b0;
        text-align: center;
        padding: 20px;
        border-radius: 6px 6px 0px 0px;
        background-color: #f2f2f2;
    }
    
    .popup-section .popup-top-bar h1 {
        font-size: 1rem;
        font-weight: 700;
        margin: 0;
    }
    
    .popup-section .popup-icon {
        width: 50px;
        margin: auto;
        margin-top: 10px;
    }
    
    .popup-section .popup-icon img {
        width: 100%;
    }
    
    .popup-section .popup-text {
        padding: 10px 50px 10px 50px;
    }
    
    .popup-section .popup-text p {
        margin-bottom: 20px;
    }
    
    .popup-section .code-text {
        padding: 20px 37px 20px 37px
    }
    
    .popup-section .code-text .code-input {
        border: none;
        border-bottom: 1px solid $borderColor;
        border-radius: 0px;
        width: 350px;
        color: #000;
    }
    
    .popup-section .popup-button {
        text-align: center;
        margin-bottom: 20px;
    }
    
    .popup-section .popup-button .Proceed {
        margin: 0px 20px 0px 20px;
    }
    
    .popup-section .popup-flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: center;
        max-width: 378px;
        margin: auto;
        .popup-flex-bais {
            flex-basis: 48%;
            margin-bottom: 5px;
            h3 {
                font-size: 14px;
                margin: 0px;
            }
            p {
                font-size: 14px;
                margin: 0px;
            }
            input {
                border: none;
                border-bottom: 1px solid #ced4da;
                border-radius: 0px;
            }
            .form-control:focus {
                box-shadow: none;
            }
        }
    }
    
    .Customer-table p {
        font-size: $tabletdsfont;
        font-weight: 700;
        margin-bottom: 0px;
        margin-left: 11px;
    }
    
    .Customer-table p span {
        color: $greencolor;
    }
    
    .upload-table th {
        color: $greencolor;
        font-size: 0.9rem
    }
    
    .upload-table td {
        // border: solid 1px $borderColor;
        width: 290px;
        // background-color: $backgroundColor;
    }
    
    .custom-pagination {
        text-align: center;
        margin: auto;
        display: table;
    }
    
    .custom-pagination .page-link {
        background-color: transparent;
        border: none;
        color: #000;
        padding: .5rem .5rem;
    }
    
    .custom-pagination .page-item {
        font-size: 1.2rem;
        font-weight: 700;
    }
    .custom-pagination .page-item.pageof{
        font-size: 14px;
        color: #908c8c;
        padding-top: 5px;

        a{
            cursor: default;
            color: #908c8c;
        }

        span{
            color: #000;
        }
    }
    
    .custom-pagination .page-item.active .page-link {
        /* z-index: 3; */
        color: $greencolor;
        background-color: transparent;
        border-color: transparent;
    }
    
    .custom-pagination .page-link:focus {
        box-shadow: none;
    }
    
    .custom-pagination .page-link:hover {
        color: $greencolor;
    }
    
    .page-item.disabled .page-link:focus {
        box-shadow: unset;
        outline: none;
    }
    
    .page-item.disabled .page-link:hover {
        color: $greencolor;
    }
    
    .page-item.disabled .page-link {
        background-color: transparent;
        border: none;
        color: #000;
    }
    .mat-horizontal-stepper-header-container{
        display: none !important;
    }
    //////////////////////////////////////////////////Transection ////////////////////////
    .Trasection-section {}
    
    .Trasection-main {
        border-bottom: 1px solid #ced4da;
        padding-bottom: 20px;
    }
    
    .transection-inputs {
        padding-top: 30px;
        max-width: 400px;
        margin: auto;
    }
    
    .transection-inputs .recharge-button {
        display: table;
        margin: auto;
        padding-top: 30px;
    }
    
    .transection-inputs .input-style label {
        font-size: $inputLablefont;
    }
    
    .transection-inputs .input-style .form-control {
        border: none;
        border-bottom: 1px solid $borderColor;
        background-color: transparent;
        border-radius: 0px;
        font-size: $inputplaceholderfont;
    }
    
    .transection-inputs .input-style .form-control:focus {
        box-shadow: none;
    }
    
    .paymentCheckbox [type=radio] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* IMAGE STYLES */
    
    .paymentCheckbox [type=radio]+img {
        cursor: pointer;
    }
    /* CHECKED STYLES */
    
    .paymentCheckbox [type=radio]+span {
        border: 2px solid $greencolor;
        border-radius: 50%;
    }
    
    .paymentCheckbox span {
        width: 100px;
        height: 100px;
        display: block;
        cursor: pointer;
    }
    
    .paymentCheckbox {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        position: relative;
        img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .multiselect-dropdown .dropdown-btn{
        border: 0 !important;
        border-bottom: 1px solid #d6d6d6 !important;
        border-radius: 0 !important;
    }
    
    //////////////////////////////////////////////////Transection ////////////////////////
    .product-section .product-main {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .product-section .product-main .prducts-flex {
        flex-basis: 21%;
        box-shadow: -1px 4px 10px 0px rgb(0 0 0 / 48%);
        padding: 30px 15px 30px 30px;
        margin: 2%;
        background: #fff;
    }
    
    .product-section .product-main .prducts-flex .prducts-flex-main {
        display: flex;
        justify-content: space-between;
    }
    
    .product-section .product-main .prducts-flex .prducts-flex-main h1 {
        color: $greencolor;
        font-size: 1.2rem
    }
    
    .product-section .product-main .prducts-flex .prducts-flex-main .product-img-text {
        color: #ffffff;
        background-image: url(/assets/images/zekli-product.png);
        background-repeat: no-repeat;
        background-size: 80px;
        flex-basis: 50%;
        padding: 14px 0px 14px 7px;
        margin-top: -15px;
        height: 85px;
        text-align: center;
        background-position: center;
        p {
            margin-top: 10px;
            font-size: 0.7rem;
            margin-bottom: 0px;
            color: #ffffff;
        }
        h1 {
            font-size: 1rem;
            margin-bottom: 0px;
            color: #ffffff;
            font-weight: 700;
        }
    }
    
    .product-section .product-main .prducts-flex .prducts-flex-main .product-text {
        flex-basis: 70%;
    }
    
    .product-section .product-main .prducts-flex .product-Marketing-text h1 {
        font-size: 0.7rem;
        color: #000;
        margin-bottom: 0px;
    }
    
    .product-section .product-main .prducts-flex .product-Marketing-text h1 span {
        font-size: 1.1rem;
        font-weight: 700;
    }
    
    .product-section .product-main .prducts-flex .product-Marketing-text h3 {
        font-size: 0.7rem;
        margin-bottom: 0px;
        // max-width: 200px;
    }
    
    .product-section .product-main .prducts-flex .product-button {
        text-align: center;
        margin: 21px 0px 0px 0px;
    }
    
    .faqs-section h1 {
        margin-bottom: 0px;
    }
    
    .faqs-section .heading-flex {
        span {
            color: $purpleColor;
        }
    }
    
    .faqs-section .faqs-main {
        // max-width: 900px;
        margin: auto;
        // padding-top: 15px;
        padding-bottom: 15px;
    }
    
    .faqs-section .faqs-main .panel {
        margin-top: 20px;
    }
    
    .faqs-section .faqs-main .panel .panel-title h1 {
        font-size: $heading2font;
        font-weight: 500;
        color: $greencolor;
    }
    
    .faqs-section .faqs-main .card {
        background-color: #fff;
        border: 0.0625rem #e0e0e0;
        border-radius: 0.2rem;
        box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }
    
    .faqs-section .faqs-main .faqs-input h1 {
        font-size: $heading1font;
        text-align: center;
        margin-bottom: 30px;
    }
    
    .faqs-section .faqs-main .panel .card-header {
        background-image: url(/assets/images/arrow.png);
        background-position: 96%;
        background-repeat: no-repeat;
        background-size: 10px;
        transition: all 0.2s ease;
    }
    
    .faqs-section .faqs-main .panel .panel-heading {
        background-color: #f2f2f2;
    }
    
    .faqs-section .faqs-main .panel-open .card-header {
        background-image: url(/assets/images/arrow-down.png);
        background-position: 96%;
        background-repeat: no-repeat;
        background-size: 20px;
        transition: all 0.2s ease;
    }
    
    .faqs-section .faqs-main .content-custom p {
        font-size: 0.95rem;
        margin: 0;
    }
    
    .faqs-section .faqs-main .input-style .form-control {
        border: none;
        border-bottom: 1px solid #d6d6d6;
        background-color: transparent;
        border-radius: 0px;
    }
    
    .faqs-section .faqs-main .button-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: .9375rem;
            color: $greencolor;
            margin: 0px;
        }
        .Quick-Guide {
            padding: 0.5rem 0.7rem;
        }
    }
    
    .Transsection-sec .transection-table .table-main {
        box-shadow: 0px 0px 15px 0 rgb(135 135 135 / 35%);
        th {
            color: $greencolor;
            border: none;
            
            img {
                width: 10px;
                margin-bottom: 4px;
            }
        }
        td {
            font-size: $tabletdsfont;
            img {
                width: 10px;
                margin-bottom: 4px;
            }
        }
        tr:nth-child(even) {
            // background-color: #f5f5f5;
            // margin-top: 10px;
        }
    }
    
    .filter-section {
        width: 300px;
        right: 0;
        background-color: #ffffff;
        height: calc(100vh - 51px);
        transition: all 0.3s ease;
        position: fixed;
        top: 51px;
        right: 0;
        z-index: 1;
    }
    
    .filter-section.hideShowFilters {
        right: -300px;
    }
    
    .filter-btn-main .filter-button .filter-btn-main .filter-button.active .filter-section {
        display: block;
        padding: 0px 20px;
        box-shadow: -4px 3px 9px 0px #bbb9b9;
    }
    
    .filter-main {
        margin-top: 0px;
    }
    
    .filter-btn-main .filter-button {
        width: 35px;
        height: 35px;
        background-color: #ced4da;
        display: block;
        border-radius: 7px;
        position: absolute;
        top: 56px;
        left: -36px;
        z-index: 99999;
        cursor: pointer;
    }
    
    .filter-button img {
        width: 100%;
    }
    
    .filter-main {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 51px);
        padding: 0px 20px 0px 20px;
    }
    
    .filter-main .filter-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    
    .filter-main .filter-button .Search-btn {
        flex-basis: 48%;
    }
    
    .filter-main .filter-button .reset-btn {
        flex-basis: 48%;
        margin-left: 2%;
    }
    
    .filter-main .filter-inputs .form-control {
        height: 30px;
        box-shadow: none;
    }
    
    .filter-main .filter-inputs .form-control:focus {
        outline: none;
    }
    
    .filter-main .filter-heading {
        font-size: $heading1font;
        color: $greencolor;
        margin-top: 10px;
    }
    
    .Phone-number-input {
        margin-left: 50px;
    }
    
    .complete-ref-skip {
        margin-right: 10px;
        background-color: transparent !important;
        color: #73bb38 !important;
        text-decoration: none !important;
    }
    
    .Available-Balance {
        font-size: 13px;
        text-align: center;
    }
    
    .Balance-min-width {
        min-width: 400px;
    }
    
    .modal-close .close {
        float: unset;
        text-shadow: unset;
        opacity: unset;
        /* text-align: center; */
    }
    
    .modal-padding {
        padding: 4rem !important;
    }
    
    .modal-body.modal-padding h4 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    
    .modal-body.modal-padding .clr-red {
        color: red;
    }
    
    .modal-body.modal-padding .bck-clr {
        background-color: yellow;
    }
    
    .modal-close .close:hover {
        opacity: unset !important;
        color: unset;
        /* text-align: center; */
    }
    
    .modal-close .moda-btn {
        padding: 0.6875rem 1.125rem;
    }
    
    .page-item.disabled .page-link {
        color: gray !important;
    }


    ///////////////////////////////////Profile status
    .unverified{
        font-weight: 700;
        color: $purpleColor;
        margin: 10px;
    }
    .verified{
        font-weight: 700;
        color: $greencolor;
        margin: 10px;
    }

    .carousel {
        text-align: -webkit-center;
        text-align: center;
    }